<template>
  <div class="container-fluid">
    <h1>The Guiding Angel</h1>
    <p>
      With their wings, angels cuddle the dreams of the stars while a rain of
      comets scatters the night with magic.
    </p>
    <p>
      This spread protects your dreams bringing hope and good luck ...
      <br />
      (Spread by Luciana Navone Nosari)
    </p>
    <div class="spread-buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="revealAll"
        v-if="!reset"
      >
        Reveal all cards
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="resetAll"
        v-if="reset"
      >
        Reset
      </button>
    </div>
    <table class="table-borderless table-responsive">
      <tbody>
        <tr>
          <td></td>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The goal"
              id="first-top"
              alt="The goal"
              v-bind:src="response[0].chosen ? getPic(0) : getPic('back')"
              v-on:click.prevent.self="reverse(0, $event)"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Hopes"
              id="fifth-top"
              alt="Hopes"
              v-bind:src="response[4].chosen ? getPic(4) : getPic('back')"
              v-on:click.prevent.self="reverse(4, $event)"
            />
          </td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Mind"
              id="second-top"
              alt="Mind"
              v-bind:src="response[1].chosen ? getPic(1) : getPic('back')"
              v-on:click.prevent.self="reverse(1, $event)"
            />
          </td>
          <td class="rotate30plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Rebirth"
              id="sixth-top"
              alt="Rebirth"
              v-bind:src="response[5].chosen ? getPic(5) : getPic('back')"
              v-on:click.prevent.self="reverse(5, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Emotions"
              id="third-top"
              alt="Emotions"
              v-bind:src="response[2].chosen ? getPic(2) : getPic('back')"
              v-on:click.prevent.self="reverse(2, $event)"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Your fears"
              id="fourth-top"
              alt="Your fears"
              v-bind:src="response[3].chosen ? getPic(3) : getPic('back')"
              v-on:click.prevent.self="reverse(3, $event)"
            />
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <Oracle v-bind:counter="counter" v-bind:oracle="oracle" />
  </div>
</template>

<script>
import { spreadMethod } from "../../mixins/spreadMethod.js";
import _ from "underscore";
import Oracle from "../../components/Oracle.vue";
export default {
  mixins: [spreadMethod],
  components: { Oracle },
  name: "GuidingAngel",
  data: function () {
    return {
      methodCards: 6,
      counter: 6,
    };
  },
  created() {
    this.response = _.sample(
      JSON.parse(JSON.stringify(this.allTarotsList)),
      this.methodCards
    );
  },
};
</script>

<style scoped>
</style>