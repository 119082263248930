<template>
  <div class="spread-container pegasus">
    <h1>Pegasus</h1>
    <p>
      Pegasus was born from the blood flowing from the Medusa's severed head.
      Joy comes forth from pain, love from hate, light from darkness...
    </p>
    <p>
      Pegasus is the game of cards for the greatest challenge: to overcome your
      dark sides. Seek the light and shadow you are made of in the Card, know
      yourself to better yourself and tomorrow you will be more ... luminous.
    </p>
    <div class="spread-buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="revealAll"
        v-if="!reset"
      >
        Reveal all cards
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="resetAll"
        v-if="reset"
      >
        Reset
      </button>
    </div>
    <table class="table-borderless table-responsive">
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Potential"
              id="seventh-top"
              alt="Potential"
              v-bind:src="response[6].chosen ? getPic(6) : getPic('back')"
              v-on:click.prevent.self="reverse(6, $event)"
            />
          </td>
          <td class="rotate15minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Intuition"
              id="eighth-top"
              alt="Intuition"
              v-bind:src="response[7].chosen ? getPic(7) : getPic('back')"
              v-on:click.prevent.self="reverse(7, $event)"
            />
          </td>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The result"
              id="ninth-top"
              alt="The result"
              v-bind:src="response[8].chosen ? getPic(8) : getPic('back')"
              v-on:click.prevent.self="reverse(8, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Fears"
              id="first-top"
              alt="Fears"
              v-bind:src="response[0].chosen ? getPic(0) : getPic('back')"
              v-on:click.prevent.self="reverse(0, $event)"
            />
          </td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The essence of the problem"
              id="second-top"
              alt="The essence of the problem"
              v-bind:src="response[1].chosen ? getPic(1) : getPic('back')"
              v-on:click.prevent.self="reverse(1, $event)"
            />
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Present obstacles"
              id="third-top"
              alt="Present obstacles"
              v-bind:src="response[2].chosen ? getPic(2) : getPic('back')"
              v-on:click.prevent.self="reverse(2, $event)"
            />
          </td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The essence of the problem"
              id="fourth-top"
              alt="The essence of the problem"
              v-bind:src="response[3].chosen ? getPic(3) : getPic('back')"
              v-on:click.prevent.self="reverse(3, $event)"
            />
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="rotate30plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Internal influence"
              id="fifth-top"
              alt="Internal influence"
              v-bind:src="response[4].chosen ? getPic(4) : getPic('back')"
              v-on:click.prevent.self="reverse(4, $event)"
            />
          </td>
          <td></td>
          <td></td>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="External influence"
              id="sixth-top"
              alt="External influence"
              v-bind:src="response[5].chosen ? getPic(5) : getPic('back')"
              v-on:click.prevent.self="reverse(5, $event)"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <Oracle v-bind:counter="counter" v-bind:oracle="oracle" />
  </div>
</template>

<script>
import { spreadMethod } from "../../mixins/spreadMethod.js";
import _ from "underscore";
import Oracle from "../../components/Oracle.vue";
export default {
  mixins: [spreadMethod],
  components: { Oracle },
  name: "Pegasus",
  data: function () {
    return {
      methodCards: 9,
      counter: 9,
    };
  },
  created() {
    this.response = _.sample(
      JSON.parse(JSON.stringify(this.allTarotsList)),
      this.methodCards
    );
  },
};
</script>

<style scoped>
/* For mobile phones: */
@media only screen and (max-width: 768px) {
  img.tarot {
    max-width: 55px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  img.tarot {
    max-width: 85px;
  }
}
</style>