<template>
  <div class="container-fluid">
    <h2 class="md-headline">The Stolen Pages</h2>
    <div class="alert-message">
      <p class="md-subhead">
        These are the pages that Otto van Haring managed to save from the
        distruction of the Alchemist&apos;s laboratory in Amsterdam. The
        &quot;Stolen Pages&quot; depict new never before seen tarot spreads.
      </p>
      <!-- <div class="alert alert-danger" v-if="!checked">
        <p aria-label="Accept Condition">
          I declare that I don't work for Umbra and/or any of its associates, I
          never had and I never will.
        </p>
        <input type="checkbox" id="checkbox" v-model="checked" @change="check($event)" />
      </div> -->
    </div>
    <div class="accepted-condition">
      <router-link :to="{ name: 'EternalTruth' }">Eternal Truth</router-link> |
      <router-link :to="{ name: 'GuidingAngel' }">Guiding Angel</router-link> |
      <router-link :to="{ name: 'Chartreux' }">Chartreux</router-link> |
      <router-link :to="{ name: 'MysticalLadder' }">Mystical Ladder</router-link>
      |
      <router-link :to="{ name: 'Maze' }">Maze</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>


<script>
export default {
  name: "StolenPages",
  computed: {
    checked() {
      return this.$store.getters.getStolenCheck;
    },
  },
  methods: {
    check: function (e) {
      this.$store.dispatch("setStolenCheck", e.returnValue);
    }
  }
};
</script>

<style scoped>
.spreads {
  width: 100%;
  clear: left;
}

.alert.alert-danger {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.accepted-condition p,
.md-subhead {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
</style>