<template>
  <div class="spread-container dragon">
    <h1>The Dragon</h1>
    <p>
      Fire and Darkness. Light and shadow! The great dragon is the shape of the
      present situation.
    </p>
    <p>
      His membrane wings fly towards the future. Shuffle the cards, ask your
      question and forge your destiny on his fiery maw.
    </p>
    <div class="spread-buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="revealAll"
        v-if="!reset"
      >
        Reveal all cards
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="resetAll"
        v-if="reset"
      >
        Reset
      </button>
    </div>
    <table class="table-borderless table-responsive">
      <tbody>
        <tr>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The aim"
              id="first-top"
              alt="The aim"
              v-bind:src="response[0].chosen ? getPic(0) : getPic('back')"
              v-on:click.prevent.self="reverse(0, $event)"
            />
          </td>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Past obstacles"
              id="fourth-top"
              alt="Past obstacles"
              v-bind:src="response[3].chosen ? getPic(3) : getPic('back')"
              v-on:click.prevent.self="reverse(3, $event)"
            />
          </td>
          <td></td>
          <td class="rotate30plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="External help"
              id="eleventh-top"
              alt="External help"
              v-bind:src="response[10].chosen ? getPic(10) : getPic('back')"
              v-on:click.prevent.self="reverse(10, $event)"
            />
          </td>
        </tr>
        <tr>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Fears"
              id="second-top"
              alt="Fears"
              v-bind:src="response[1].chosen ? getPic(1) : getPic('back')"
              v-on:click.prevent.self="reverse(1, $event)"
            />
          </td>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Present obstacles"
              id="third-top"
              alt="Present obstacles"
              v-bind:src="response[2].chosen ? getPic(2) : getPic('back')"
              v-on:click.prevent.self="reverse(2, $event)"
            />
          </td>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Intuition"
              id="fifth-top"
              alt="Intuition"
              v-bind:src="response[4].chosen ? getPic(4) : getPic('back')"
              v-on:click.prevent.self="reverse(4, $event)"
            />
          </td>
          <td class="rotate60plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Potential"
              id="tenth-top"
              alt="Potential"
              v-bind:src="response[9].chosen ? getPic(9) : getPic('back')"
              v-on:click.prevent.self="reverse(9, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="rotate15minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Internal influence"
              id="sixth-top"
              alt="Internal influence"
              v-bind:src="response[5].chosen ? getPic(5) : getPic('back')"
              v-on:click.prevent.self="reverse(5, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="rotate30plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="External influence"
              id="seventh-top"
              alt="External influence"
              v-bind:src="response[6].chosen ? getPic(6) : getPic('back')"
              v-on:click.prevent.self="reverse(6, $event)"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The other"
              id="eighth-top"
              alt="The other"
              v-bind:src="response[7].chosen ? getPic(7) : getPic('back')"
              v-on:click.prevent.self="reverse(7, $event)"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="rotate60minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The result"
              id="ninth-top"
              alt="The result"
              v-bind:src="response[8].chosen ? getPic(8) : getPic('back')"
              v-on:click.prevent.self="reverse(8, $event)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <Oracle v-bind:counter="counter" v-bind:oracle="oracle" />
  </div>
</template>

<script>
import { spreadMethod } from "../../mixins/spreadMethod.js";
import _ from "underscore";
import Oracle from "../../components/Oracle.vue";
export default {
  mixins: [spreadMethod],
  components: { Oracle },
  name: "Dragon",
  data: function () {
    return {
      methodCards: 11,
      counter: 11,
    };
  },
  created() {
    this.response = _.sample(
      JSON.parse(JSON.stringify(this.allTarotsList)),
      this.methodCards
    );
  },
};
</script>

<style scoped>
</style>