<template>
  <div class="container-fluid">
    <div>
      <router-link :to="{ name: 'LittleBear' }">Little Bear</router-link> |
      <router-link :to="{ name: 'Cassiopea' }">Cassiopea</router-link> |
      <router-link :to="{ name: 'Dragon' }">Dragon</router-link> |
      <router-link :to="{ name: 'Pegasus' }">Pegasus</router-link> |
      <router-link :to="{ name: 'Swan' }">Swan</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Spreads",
  data() {
    return {
      search: null,
      tempDeck: [],
    };
  },
  methods: {
    getImageUrl(imageName) {
      return require("../assets/img/" + this.deckName + "/" + imageName);
    },
    filterCards(val) {
      let toLowerCaseValue = val.toLowerCase();
      this.tempDeck = [];
      this.tempDeck = this.deck.filter((card) =>
        card.name.toLowerCase().includes(toLowerCaseValue)
      );
    },
    getClass(index) {
      return (
        "animate__animated animate__bounceInRight animate__delay-" +
        index +
        "00ms"
      );
    },
  },
  watch: {
    search(val) {
      this.filterCards(val);
    },
  },
  computed: {
    deck() {
      if (this.tempDeck.length > 0) {
        return this.tempDeck;
      } else {
        return this.$store.getters.decks;
      }
    },
    deckName() {
      return this.$store.getters.deckName;
    },
  },
};
</script>

<style scoped>
img {
  max-width: 150px !important;
}
</style>
