<template>
  <div class="oracle" v-show="counter == 0">
    <ul>
      <li v-for="(tarot, index) in oracle" v-bind:key="index">
        <h2>
          <strong>{{ tarot.title }}</strong
          >: the {{ tarot.name }}
        </h2>
        <img
          class="img-fluid animate__animated animate__fadeIn"
          v-bind:src="getPic(tarot.order)"
        />
        <p class="oracle-meaning">... {{ tarot.meaning }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Oracle",
  props: ["counter", "oracle"],
  methods: {
    getPic: function (order) {
      return require("../assets/img/" +
        this.$store.getters.getDeckName +
        "/straight/" +
        order +
        ".png");
    },
  },
};
</script>
<style scoped>
.oracle-meaning {
font-size: 2rem;
}
</style>