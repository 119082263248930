<template>
  <div>
    <h1>Cassiopea</h1>
    <p>
      Love, fierce passions, important relationships. Cassiopeia the Beauty
      scrutinizes the mirror looking for an answer to her worries in the
      reflection.
    </p>
    <p>Ask your question and read the reflection of destiny in the cards..</p>
    <div class="spread-buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="revealAll"
        v-if="!reset"
      >
        Reveal all cards
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="resetAll"
        v-if="reset"
      >
        Reset
      </button>
    </div>
    <table class="table-borderless table-responsive">
      <tbody>
        <tr>
          <td></td>
          <td class="rotate30plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The you"
              id="first-top"
              alt="The you"
              v-bind:src="response[0].chosen ? getPic(0) : getPic('back')"
              v-on:click.self="reverse(0, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The other"
              id="second-top"
              alt="The other"
              v-bind:src="response[1].chosen ? getPic(1) : getPic('back')"
              v-on:click.self="reverse(1, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate30plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The relationship"
              id="third-top"
              alt="The relationship"
              v-bind:src="response[2].chosen ? getPic(2) : getPic('back')"
              v-on:click.self="reverse(2, $event)"
            />
          </td>
        </tr>
        <tr>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Present obstacles"
              id="fourth-top"
              alt="Present obstacles"
              v-bind:src="response[3].chosen ? getPic(3) : getPic('back')"
              v-on:click.self="reverse(3, $event)"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Intuition"
              id="fifth-top"
              alt="Intuition"
              v-bind:src="response[4].chosen ? getPic(4) : getPic('back')"
              v-on:click.self="reverse(4, $event)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <Oracle v-bind:counter="counter" v-bind:oracle="oracle" />
  </div>
</template>

<script>
import { spreadMethod } from "../../mixins/spreadMethod.js";
import _ from "underscore";
import Oracle from "../../components/Oracle.vue";

export default {
  mixins: [spreadMethod],
  components: { Oracle },
  name: "Cassiopea",
  data: function () {
    return {
      methodCards: 5,
      counter: 5,
    };
  },
  created() {
    this.response = _.sample(
      JSON.parse(JSON.stringify(this.allTarotsList)),
      this.methodCards
    );
  },
};
</script>

<style scoped>
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  table {
    width: 65%;
  }
}
</style>
