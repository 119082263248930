import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import decks from '@/store/modules/decks.module'
// VUEJS 2
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    decks
  },
  plugins: [createPersistedState(
    {
      storage: window.sessionStorage,
    }
  )]
});
