<template>
  <div class="container-fluid">
    <h4>The decoder of the Book of Shadows</h4>
    <h3 id="shine-title">Ebenezar Everard Prescott presents</h3>
    <h1 id="headline">De Divinatione Unveiled</h1>
    <h3>
      Esoteric Interpretations of Alchemistic Symbology, Magic and Other
      Mysteries of the Universe
    </h3>
    <iframe
      id="video_background"
      src="https://www.youtube.com/embed/a9b4tvIp6II"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "Homepage",
  props: {
    msg: String,
  },
};
</script>

<style>
#video_background {
  width: 560px;
  height: 315px;
}

/*GENERAL CSS SETTINGS*/

.spread-snippet {
  margin: auto;
  max-width: 600px;
}
.spread-container {
  background: #070416;
  min-height: 150px;
  margin-left: 10px;
  margin-right: 10px;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

img.tarot {
  max-width: 100px;
}
.rotate30plus {
  transform: rotateZ(30deg) !important;
}
.rotate30minus {
  transform: rotateZ(-30deg) !important;
}

.rotate60plus {
  transform: rotateZ(60deg) !important;
}

.rotate60minus {
  transform: rotateZ(-60deg) !important;
}

.rotate15plus {
  transform: rotateZ(15deg) !important;
}

.rotate15minus {
  transform: rotateZ(-15deg) !important;
}

.rotate90plus {
  transform: rotateZ(90deg) !important;
}

table {
  margin-left: auto;
  margin-right: auto;
  padding-left: auto;
  padding-right: auto;
  overflow: visible !important;
  height: 100%;
  margin-top: 10px;
}

li {
  list-style: none;
}

.oracle {
  margin-top: 10px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 100px 10px 100px;
}
span.meaning {
  display: block;
  max-width: 200px;
}
div.image-meaning {
  display: block;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #862806 !important;
  background-image: url("../assets/img/logo-128x128.png");
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.spread-buttons {
  margin: 5px;
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
  #app {
    padding-left: 1px;
    padding-right: 1px;
  }

  div.homepage {
    padding: 0;
    margin: 0;
  }

  #video_background {
    max-width: 100%;
  }

  img.tarot {
    max-width: 75px;
  }
  table {
    width: 100%;
  }
  .oracle {
    margin-top: 10px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 1px;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #video_background {
    max-width: 100%;
  }

  img.tarot {
    max-width: 90px;
  }
}
</style>
