<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/alltarots">All Tarots</router-link> |
      <router-link to="/spreads">Spreads</router-link> |
      <router-link to="/stolen-pages">Stolen Pages</router-link>
    </div>
    <router-view />
  </div>
</template>

<style>
body {
  height: 100%;
  width: 100%;
  background-color: #070416 !important;
  color: #862806 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #7c795d !important;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  background-color: inherit;
  height: 100%;
  width: 100%;
}
@font-face {
  font-family: "Playfair Display", serif;
}

/* unvisited link */
.custom-link {
  background-color: #070416 !important;
  margin-left: auto;
  margin-right: auto;
  color: #862806 !important;
}

a {
  color: #862806 !important;
  text-decoration: underline !important;
}
table tbody {
  display: inline;
}
</style>
