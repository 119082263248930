import json from '@/assets/tarots.json';
const state = {
    currentDeck: [],
    deckName: 'default',
    decks: [],
    stolenCheck: false
}

const actions = {
    async setDeckName({ commit }, requestedDeckName) {
        let requestedDeckNameVar = requestedDeckName ? requestedDeckName : 'default'
        commit('SET_DECKNAME', requestedDeckNameVar)
    },
    async setCards({ commit }, currentDeckName) {
        const response = json[currentDeckName]
        commit('SET_DECK', response)
    },
    async setStolenCheck({ commit }, stolenCheck) {
        commit('SET_STOLENCHECK', stolenCheck)
    }

}

const getters = {
    getCurrentDeck: state => {
        return state.currentDeck
    },
    getDeckName: state => {
        return state.deckName
    },
    getStolenCheck: state => {
        return state.stolenCheck
    }
}

const mutations = {
    SET_DECK(state, deck) {
        state.currentDeck = deck
    },
    SET_DECKNAME(state, deckName) {
        state.deckName = deckName
    },
    SET_STOLENCHECK(state, stolenCheck) {
        if (typeof stolenCheck != "boolean") {
            stolenCheck = false
        }
        state.stolenCheck = stolenCheck
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
