import Vue from 'vue';
import Router from 'vue-router';
import Homepage from '@/views/Homepage.vue';
import Spreads from '@/views/Spreads.vue';
import StolenPages from "@/views/StolenPages.vue";
import LittleBear from '@/views/spreads/LittleBear.vue';
import Cassiopea from '@/views/spreads/Cassiopea.vue';
import Dragon from '@/views/spreads/Dragon.vue';
import Pegasus from '@/views/spreads/Pegasus.vue';
import Swan from '@/views/spreads/Swan.vue';
import EternalTruth from '@/views/stolenpages/EternalTruth.vue';
import GuidingAngel from '@/views/stolenpages/GuidingAngel.vue';
import Chartreux from '@/views/stolenpages/Chartreux.vue';
import MysticalLadder from '@/views/stolenpages/MysticalLadder.vue';
import Maze from '@/views/stolenpages/Maze.vue';
import { createWebHistory, createRouter } from "vue-router";


// Vue.use(Router)
// VUEJS 2 
// export default new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
  
// })

const routes = [
  {
    path: '/',
    name: 'home',
    component: Homepage
  },
  {
    path: '/alltarots',
    name: 'All Tarots',
    component: () => import('./views/AllTarots.vue')
  },
  {
    path: '/spreads',
    name: 'Spreads',
    component: Spreads,
    children: [
      {
        path: '',
        redirect: '/spreads/little-bear', // default child path
      },
      {
        name: 'LittleBear',
        path: 'little-bear',
        component: LittleBear
      },
      {
        name: 'Cassiopea',
        path: 'cassiopea',
        component: Cassiopea
      },
      {
        name: 'Dragon',
        path: 'dragon',
        component: Dragon
      },
      {
        name: 'Pegasus',
        path: 'pegasus',
        component: Pegasus
      },
      {
        name: 'Swan',
        path: 'swan',
        component: Swan
      }
    ]
  },
  {
    path: '/stolen-pages',
    name: 'StolenPages',
    component: StolenPages,
    children: [
      {
        path: '',
        redirect: '/stolen-pages/eternal-truth', // default child path
      },
      {
        name: 'EternalTruth',
        path: 'eternal-truth',
        component: EternalTruth
      },
      {
        name: 'GuidingAngel',
        path: 'guiding-angel',
        component: GuidingAngel
      },
      {
        name: 'Chartreux',
        path: 'chartreux',
        component: Chartreux
      },
      {
        name: 'MysticalLadder',
        path: 'mystical-ladder',
        component: MysticalLadder
      },
      {
        name: 'Maze',
        path: 'maze',
        component: Maze
      }
    ]
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;