<template>
  <div>
    <!-- TITLE AND SNIPPET -->
    <h1>The Little Bear</h1>
    <p>
      The Little Bear, known in the ancient Babylonian star catalogues as the
      Wagon of Heaven, will lead you toward the future.
    </p>
    <p>
      The spread tells the truth about you in correlation with another person or
      group, who are important to your life ...
    </p>
    <div class="spread-buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="revealAll"
        v-if="!reset"
      >
        Reveal all cards
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="resetAll"
        v-if="reset"
      >
        Reset
      </button>
    </div>
    <table class="table-borderless table-responsive">
      <tbody>
        <tr>
          <td>
            <img
              class="tarot animate__animated animate__fadeIn"
              title="The you"
              id="response[0].id + '-top'"
              alt="The you"
              v-bind:src="response[0].chosen ? getPic(0) : getPic('back')"
              v-on:click.prevent.self="reverse(0, $event)"
            />
          </td>
          <td>
            <img
              class="tarot animate__animated animate__fadeIn"
              title="Fears"
              id="second-top"
              alt="Fears"
              v-bind:src="response[1].chosen ? getPic(1) : getPic('back')"
              v-on:click.prevent.self="reverse(1, $event)"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <img
              class="tarot animate__animated animate__fadeIn"
              title="The other"
              id="third-top"
              alt="The other"
              v-bind:src="response[2].chosen ? getPic(2) : getPic('back')"
              v-on:click.prevent.self="reverse(2, $event)"
            />
          </td>
          <td>
            <img
              class="tarot animate__animated animate__fadeIn"
              title="The other's fears"
              id="fourth-top"
              alt="The other's fear"
              v-bind:src="response[3].chosen ? getPic(3) : getPic('back')"
              v-on:click.prevent.self="reverse(3, $event)"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="rotate30minus">
            <img
              class="tarot animate__animated animate__fadeIn"
              title="The relationship"
              id="fifth-top"
              alt="The relationship"
              v-bind:src="response[4].chosen ? getPic(4) : getPic('back')"
              v-on:click.prevent.self="reverse(4, $event)"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <img
              class="tarot animate__animated animate__fadeIn"
              title="Intuition"
              id="sixth-top"
              alt="Intuition"
              v-bind:src="response[5].chosen ? getPic(5) : getPic('back')"
              v-on:click.prevent.self="reverse(5, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="rotate30plus">
            <img
              class="tarot animate__animated animate__fadeIn"
              title="Potential"
              id="seventh-top"
              alt="Potential"
              v-bind:src="response[6].chosen ? getPic(6) : getPic('back')"
              v-on:click.prevent.self="reverse(6, $event)"
            />
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <Oracle v-bind:counter="counter" v-bind:oracle="oracle" />
  </div>
</template>

<script>
import { spreadMethod } from "../../mixins/spreadMethod.js";
import _ from "underscore";
import Oracle from "../../components/Oracle.vue";

export default {
  mixins: [spreadMethod],
  components: { Oracle },
  name: "LittleBear",
  data: function () {
    return {
      counter: 7,
      methodCards: 7,
    };
  },
  created() {
    this.response = _.sample(
      JSON.parse(JSON.stringify(this.allTarotsList)),
      this.methodCards
    );
  },
};
</script>

<style scoped>
.img {
  animation: fadeIn;
}
.no-border td {
  border: none !important;
}
.table-responsive {
  overflow: unset !important;
}
</style>
