<template>
  <div class="container-fluid">
    <h1>The Mystical Ladder</h1>
    <p></p>
    <p>
      This spread can be repeated several times, adjusting all cards as
      necessary, except the Goal card which will remain the same until you reach
      that goal, or change that goal.
      <br />
      (Spread by TABI - Tarot Association of the British Isles)
    </p>
    <div class="spread-buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="revealAll"
        v-if="!reset"
      >
        Reveal all cards
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="resetAll"
        v-if="reset"
      >
        Reset
      </button>
    </div>
    <table class="table-borderless table-responsive">
      <tbody>
        <tr>
          <td></td>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="You obtained your goal"
              id="second-top"
              alt="You obtained your goal"
              v-bind:src="response[1].chosen ? getPic(1) : getPic('back')"
              v-on:click.prevent.self="reverse(1, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="External help"
              id="fifth-top"
              alt="External help"
              v-bind:src="response[4].chosen ? getPic(4) : getPic('back')"
              v-on:click.prevent.self="reverse(4, $event)"
            />
          </td>
        </tr>
        <tr>
          <td class="rotate15plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Your fears"
              id="third-top"
              alt="Your fears"
              v-bind:src="response[2].chosen ? getPic(2) : getPic('back')"
              v-on:click.prevent.self="reverse(2, $event)"
            />
          </td>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Inner help"
              id="fourth-top"
              alt="Inner help"
              v-bind:src="response[3].chosen ? getPic(3) : getPic('back')"
              v-on:click.prevent.self="reverse(3, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="You"
              id="first-top"
              alt="You"
              v-bind:src="response[0].chosen ? getPic(0) : getPic('back')"
              v-on:click.prevent.self="reverse(0, $event)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <Oracle v-bind:counter="counter" v-bind:oracle="oracle" />
  </div>
</template>

<script>
import { spreadMethod } from "../../mixins/spreadMethod.js";
import _ from "underscore";
import Oracle from "../../components/Oracle.vue";
export default {
  mixins: [spreadMethod],
  components: { Oracle },
  name: "MysticalLadder",
  data: function () {
    return {
      methodCards: 5,
      counter: 5,
    };
  },
  created() {
    this.response = _.sample(
      JSON.parse(JSON.stringify(this.allTarotsList)),
      this.methodCards
    );
  },
};
</script>

<style scoped>
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  table {
    width: 75%;
  }
}
</style>