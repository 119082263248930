<template>
  <div class="container-fluid">
    <h1>The Maze</h1>
    <p>
      Out of the darkness into the light, journey through the maze to the
      realization of your goal.
    </p>
    <p>
      Choose the cards and follow the path using your intuition and focus.
      <br />
      (Spread by Linda Henery)
    </p>
    <div class="spread-buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="revealAll"
        v-if="!reset"
      >
        Reveal all cards
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="resetAll"
        v-if="reset"
      >
        Reset
      </button>
    </div>
    <table class="table-borderless table-responsive">
      <tbody>
        <tr>
          <td></td>
          <td class="rotate60plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The result"
              id="fifth-top"
              alt="The result"
              v-bind:src="response[4].chosen ? getPic(4) : getPic('back')"
              v-on:click.prevent.self="reverse(4, $event)"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Your potential"
              id="fourth-top"
              alt="Your potential"
              v-bind:src="response[3].chosen ? getPic(3) : getPic('back')"
              v-on:click.prevent.self="reverse(3, $event)"
            />
          </td>
          <td></td>
          <td></td>
          <td class="rotate60minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Present obstacles"
              id="first-top"
              alt="Present obstacles"
              v-bind:src="response[0].chosen ? getPic(0) : getPic('back')"
              v-on:click.prevent.self="reverse(0, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate60minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="The Intuition"
              id="third-top"
              alt="The intuition"
              v-bind:src="response[2].chosen ? getPic(2) : getPic('back')"
              v-on:click.prevent.self="reverse(2, $event)"
            />
          </td>
          <td class="rotate60plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Your fears"
              id="second-top"
              alt="Your fears"
              v-bind:src="response[1].chosen ? getPic(1) : getPic('back')"
              v-on:click.prevent.self="reverse(1, $event)"
            />
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <Oracle v-bind:counter="counter" v-bind:oracle="oracle" />
  </div>
</template>

<script>
import { spreadMethod } from "../../mixins/spreadMethod.js";
import _ from "underscore";
import Oracle from "../../components/Oracle.vue";
export default {
  mixins: [spreadMethod],
  components: { Oracle },
  name: "Maze",
  data: function () {
    return {
      methodCards: 5,
      counter: 5,
    };
  },
  created() {
    this.response = _.sample(
      JSON.parse(JSON.stringify(this.allTarotsList)),
      this.methodCards
    );
  },
};
</script>

<style scoped>
</style>