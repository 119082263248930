import _ from 'underscore';
import VueScrollTo from 'vue-scrollto';

export const spreadMethod = {
  name: "Spread",
  props: [
    'tarotsObject',
    'deck'
  ],
  data: function () {
    return {
      oracle: {},
      methodCards: 5,
      counter: 5,
      reset: false,
      response: {}
    }
  },
  computed: {
    deckName() {
      return this.$store.getters.getDeckName;
    },
    allTarotsList() {
      this.$store.dispatch('setCards', this.deckName);
      return this.$store.getters.getCurrentDeck;
    },
  },
  methods: {
    getPic: function (index) {
      if (index == 'back') {
        return require('../assets/img/' + this.$store.getters.getDeckName + '/straight/back.png')
      }
      else {
        return require('../assets/img/' + this.$store.getters.getDeckName + '/straight/' + this.response[index].order + ".png")
      }
    },
    reverse: function (index, $event) {
      if (this.response[index].chosen == true) {
        return;
      }

      this.response[index].chosen = !this.response[index].chosen

      // Random animations to apply.
      let animations = ['animate__flipInY', 'animate__flip', 'animate__rotateIn', 'animate__slideInLeft', 'animate__zoomIn', 'animate__jackInTheBox', 'animate__rollIn']
      // Select one animation.
      let animation = _.sample(animations)

      // Apply the animation.
      $event.target.className += 'animate__animated ' + animation
      this.oracle[index] = {
        title: $event.target.title,
        name: this.response[index].name,
        meaning: this.response[index].meaning,
        order: this.response[index].order
      }
      this.counter -= 1
      if (this.counter == 0) {
        setTimeout(function () {
          VueScrollTo.scrollTo('div.oracle', 500, { easing: 'ease-out' });
        }, 1000);
      }
    },
    revealAll: function () {
      let tarots = document.querySelectorAll('img.tarot');
      for (const tarot of tarots) {
        tarot.click();
      }
      this.reset = true;
    },
    resetAll: function () {
      this.oracle = {};
      this.reset = false;
      this.counter = this.methodCards;
      this.response = _.sample(JSON.parse(JSON.stringify(this.allTarotsList)), this.methodCards);
    }
  },
}
