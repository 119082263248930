<template>
  <div class="spread-container swan">
    <h1>Swan</h1>
    <p>The swan flies above the spheres of fixed stars towards its dream.</p>
    <p>
      The swan sings advice to those with a dream to fulfill. Its wings will
      take you far away, hold on tight and go where you must.
    </p>
    <div class="spread-buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="revealAll"
        v-if="!reset"
      >
        Reveal all cards
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        v-on:click="resetAll"
        v-if="reset"
      >
        Reset
      </button>
    </div>
    <table class="table-borderless table-responsive">
      <tbody>
        <tr>
          <td></td>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Essence of the problem"
              id="eighth-top"
              alt="Essence of the problem"
              v-bind:src="response[7].chosen ? getPic(7) : getPic('back')"
              v-on:click.prevent.self="reverse(7, $event)"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate30plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="External help"
              id="seventh-top"
              alt="External help"
              v-bind:src="response[6].chosen ? getPic(6) : getPic('back')"
              v-on:click.prevent.self="reverse(6, $event)"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Past obstacles"
              id="fourth-top"
              alt="Past obstacles"
              v-bind:src="response[3].chosen ? getPic(3) : getPic('back')"
              v-on:click.prevent.self="reverse(3, $event)"
            />
          </td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Potential"
              id="third-top"
              alt="Potential"
              v-bind:src="response[2].chosen ? getPic(2) : getPic('back')"
              v-on:click.prevent.self="reverse(2, $event)"
            />
          </td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              ng-click="cardSelect($event)"
              title="Present obstacles"
              id="second-top"
              alt="Present obstacles"
              v-bind:src="response[1].chosen ? getPic(1) : getPic('back')"
              v-on:click.prevent.self="reverse(1, $event)"
            />
          </td>
          <td class="rotate90plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Fears"
              id="first-top"
              alt="Fears"
              v-bind:src="response[0].chosen ? getPic(0) : getPic('back')"
              v-on:click.prevent.self="reverse(0, $event)"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate30minus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="External influcence"
              id="fifth-top"
              alt="External influcence"
              v-bind:src="response[4].chosen ? getPic(4) : getPic('back')"
              v-on:click.prevent.self="reverse(4, $event)"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="rotate30plus">
            <img
              class="tarot img-fluid animate__animated animate__fadeIn"
              title="Internal help"
              id="sixth-top"
              alt="Internal help"
              v-bind:src="response[5].chosen ? getPic(5) : getPic('back')"
              v-on:click.prevent.self="reverse(5, $event)"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <Oracle v-bind:counter="counter" v-bind:oracle="oracle" />
  </div>
</template>
<script>
import { spreadMethod } from "../../mixins/spreadMethod.js";
import _ from "underscore";
import Oracle from "../../components/Oracle.vue";
export default {
  mixins: [spreadMethod],
  components: { Oracle },
  name: "Swan",
  data: function () {
    return {
      methodCards: 8,
      counter: 8,
    };
  },
  created() {
    this.response = _.sample(
      JSON.parse(JSON.stringify(this.allTarotsList)),
      this.methodCards
    );
  },
};
</script>

<style scoped>
</style>